import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Button, Grid } from "@mui/material";
import { Add, CalendarMonth } from "@mui/icons-material";
import { DataGrid } from "@mui/x-data-grid";

const ShoppingPage = () => {
  const { t } = useTranslation();
  useEffect(() => {
    document.title = t("merchpage.merch");
  }, [t]);
  return (
    <Grid
      container
      spacing={2}
      sx={{
        margin: "0 auto",
        maxWidth: "1300px",
        minHeight: "50vh",
        padding: {
          xs: "7rem 1rem",
          sm: "8rem 1rem",
          md: "8rem 1rem",
          xl: "8rem 1rem",
        },
      }}
    >
      <Grid
        item
        xs={12}
        md={12}
        style={{ margin: "0 auto", maxWidth: "1300px" }}
      >
        Onlinebeställning
      </Grid>
      <Grid item xs={12}>
        <DataGrid
          rows={[
            {
              id: 1,
              articleName: "Banana",
              articleVariety: "Cavendish",
              piecesPerUnit: 6,
              pieceWeight: "150g",
              boxWeight: "1kg",
              countryOfOrigin: "Ecuador",
              price: "12.99 SEK",
              quantityInStock: 500,
            },
            {
              id: 2,
              articleName: "Apple",
              articleVariety: "Granny Smith",
              piecesPerUnit: 4,
              pieceWeight: "200g",
              boxWeight: "1.5kg",
              countryOfOrigin: "USA",
              price: "10.99 SEK",
              quantityInStock: 400,
            },
            {
              id: 3,
              articleName: "Orange",
              articleVariety: "Valencia",
              piecesPerUnit: 5,
              pieceWeight: "180g",
              boxWeight: "1.2kg",
              countryOfOrigin: "Spain",
              price: "9.99 SEK",
              quantityInStock: 300,
            },
            {
              id: 4,
              articleName: "Grapes",
              articleVariety: "Red Globe",
              piecesPerUnit: 250,
              pieceWeight: "1kg",
              boxWeight: "5kg",
              countryOfOrigin: "Italy",
              price: "79.99 SEK",
              quantityInStock: 200,
            },
            {
              id: 5,
              articleName: "Strawberries",
              articleVariety: "Sweet Charlie",
              piecesPerUnit: 12,
              pieceWeight: "25g",
              boxWeight: "500g",
              countryOfOrigin: "Mexico",
              price: "34.99 SEK",
              quantityInStock: 350,
            },
            {
              id: 6,
              articleName: "Broccoli",
              articleVariety: "Calabrese",
              piecesPerUnit: 2,
              pieceWeight: "300g",
              boxWeight: "600g",
              countryOfOrigin: "USA",
              price: "16.99 SEK",
              quantityInStock: 250,
            },
            {
              id: 7,
              articleName: "Carrots",
              articleVariety: "Nantes",
              piecesPerUnit: 10,
              pieceWeight: "100g",
              boxWeight: "1kg",
              countryOfOrigin: "Canada",
              price: "8.99 SEK",
              quantityInStock: 300,
            },
            {
              id: 8,
              articleName: "Tomatoes",
              articleVariety: "Beefsteak",
              piecesPerUnit: 8,
              pieceWeight: "120g",
              boxWeight: "1kg",
              countryOfOrigin: "Netherlands",
              price: "11.99 SEK",
              quantityInStock: 400,
            },
            {
              id: 9,
              articleName: "Spinach",
              articleVariety: "Bloomsdale",
              piecesPerUnit: 1,
              pieceWeight: "200g",
              boxWeight: "200g",
              countryOfOrigin: "Mexico",
              price: "10.99 SEK",
              quantityInStock: 150,
            },
            {
              id: 10,
              articleName: "Cucumbers",
              articleVariety: "Telegraph",
              piecesPerUnit: 4,
              pieceWeight: "150g",
              boxWeight: "600g",
              countryOfOrigin: "Spain",
              price: "12.99 SEK",
              quantityInStock: 200,
            },
            {
              id: 11,
              articleName: "Pineapple",
              articleVariety: "Smooth Cayenne",
              piecesPerUnit: 1,
              pieceWeight: "1.5kg",
              boxWeight: "1.5kg",
              countryOfOrigin: "Costa Rica",
              price: "34.99 SEK",
              quantityInStock: 100,
            },
            {
              id: 12,
              articleName: "Watermelon",
              articleVariety: "Crimson Sweet",
              piecesPerUnit: 1,
              pieceWeight: "5kg",
              boxWeight: "5kg",
              countryOfOrigin: "Brazil",
              price: "49.99 SEK",
              quantityInStock: 80,
            },
            {
              id: 13,
              articleName: "Potatoes",
              articleVariety: "Russet Burbank",
              piecesPerUnit: 10,
              pieceWeight: "80g",
              boxWeight: "1kg",
              countryOfOrigin: "Germany",
              price: "10.99 SEK",
              quantityInStock: 300,
            },
            {
              id: 14,
              articleName: "Mango",
              articleVariety: "Alphonso",
              piecesPerUnit: 4,
              pieceWeight: "250g",
              boxWeight: "1kg",
              countryOfOrigin: "India",
              price: "29.99 SEK",
              quantityInStock: 120,
            },
            {
              id: 15,
              articleName: "Bell Pepper",
              articleVariety: "Red Bell",
              piecesPerUnit: 3,
              pieceWeight: "150g",
              boxWeight: "450g",
              countryOfOrigin: "Mexico",
              price: "21.99 SEK",
              quantityInStock: 180,
            },
          ]}
          columns={[
            {
              headerName: "Benämning",
              field: "articleName",
              width: 300,
              renderCell: (params) =>
                params.row.articleName +
                " " +
                `"${params.row.articleVariety}", ${params.row.countryOfOrigin}`,
            },
            {
              headerName: "Vikt",
              field: "boxWeight",
              renderCell: (params) => params.row.boxWeight,
            },
            {
              headerName: "Förpackning",
              field: "piecesPerUnit",
              renderCell: (params) =>
                params.row.piecesPerUnit + " x " + params.row.pieceWeight,
            },
            {
              headerName: "I lager",
              field: "quantityInStock",
              renderCell: (params) => params.row.quantityInStock,
            },
            {
              headerName: "",
              field: "addtocart",
              width: 120,
              renderCell: (params) => (
                <Button
                  size="small"
                  variant="contained"
                  startIcon={<Add />}
                  sx={{ textTransform: "none" }}
                >
                  Köp nu
                </Button>
              ),
            },
            {
              headerName: "",
              field: "preorder",
              width: 120,
              renderCell: (params) => (
                <Button
                  variant="outlined"
                  size="small"
                  startIcon={<CalendarMonth />}
                  sx={{ textTransform: "none" }}
                >
                  Förbeställ
                </Button>
              ),
            },
          ]}
        />
      </Grid>
    </Grid>
  );
};
export default ShoppingPage;
