import React from "react";
import { Route, Routes } from "react-router-dom";
import LandingPage from "./Public/LandingPage/LandingPage";
import ShoppingPage from "./Public/Shopping/ShoppingPage";
import IntegrityPolicyPage from "./Public/IntegrityPolicy/IntegrityPolicyPage";
import CookiePolicyPage from "./Public/CookiePolicy/CookiePolicyPage";
import DeliveryStatusPage from "./Public/DeliveryStatus/DeliveryStatusPage";

function PublicApp() {
  return (
    <Routes>
      <Route path={"/"} element={<LandingPage />} />
      <Route path="/start" element={<LandingPage />} />
      <Route path="/landing" element={<LandingPage />} />
      <Route path="shop" element={<ShoppingPage />} />
      <Route path="gdpr" element={<IntegrityPolicyPage />} />
      <Route path="cookies" element={<CookiePolicyPage />} />
      <Route path="/deliverystatus/:token" element={<DeliveryStatusPage />} />
    </Routes>
  );
}

export default PublicApp;
