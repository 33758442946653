export function getArticleRowBackground(a) {
  if (a.picked) {
    return "rgb(192, 216, 193)";
  } else {
    if (a.pickedItemQuantityBoxes < a.articleAmount) {
      return "#ffcccb";
    } else if (
      a.isWeightArticle &&
      a.pickedItemQuantityUnits < a.articleUnits
    ) {
      return "#fffdaf";
    } else if (
      a.pickedItemQuantityBoxes > a.articleAmount ||
      a.pickedItemQuantityUnits > a.articleUnits
    ) {
      return "#90EE90";
    } else {
      return "white";
    }
  }
}
export function getArticleRowBackgroundV2(a) {
  if (!a.deviation) {
    return "rgb(192, 216, 193)";
  } else {
    if (a.negativeDeviation) {
      return "#ffcccb";
    } else if (a.positiveDeviation) {
      return "#fffdaf";
    } else {
      return "white";
    }
  }
}
export function getArticleRowBackgroundPurchaseOrder(a) {
  if (a.received) {
    return "rgb(192, 216, 193)";
  } else {
    if (a.receivedItemQuantityBoxes < a.itemQuantityBoxes) {
      return "#ffcccb";
    } else if (
      a.articleIsWeightArticle &&
      a.receivedItemQuantityUnits < a.itemQuantityUnits
    ) {
      return "#fffdaf";
    } else if (
      a.receivedItemQuantityBoxes > a.itemQuantityBoxes ||
      a.receivedItemQuantityUnits > a.itemQuantityUnits
    ) {
      return "#90EE90";
    } else {
      return "white";
    }
  }
}
export function getArticleTextColor(a) {
  if (a.picked) {
    return "black";
  } else {
    if (a.pickedItemQuantityBoxes < a.articleAmount) {
      return "red";
    } else if (
      a.isWeightArticle &&
      a.pickedItemQuantityUnits < a.articleUnits
    ) {
      return "red";
    } else if (
      a.pickedItemQuantityBoxes > a.articleAmount ||
      a.pickedItemQuantityUnits > a.articleUnits
    ) {
      return "black";
    } else {
      return "black";
    }
  }
}
