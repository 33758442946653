import {
  Box,
  Button,
  Card,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useParams } from "react-router-dom";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { tableCellClasses } from "@mui/material/TableCell";
import Divider from "@mui/material/Divider";
import { ShoppingCart } from "@mui/icons-material";
import { getDateDescriptionAndTime, getLocalDateTime } from "../../DateUtil";
import BettinaLoader from "../../components/BettinaLoader/BettinaLoader";
import { getArticleRowBackground } from "../../GetPickColor";
import axios from "axios";
import { getApiBaseUrl } from "../../UrlUtil";
/*
const steps = [
  "Beställningen är bekräftad",
  "Ordern förbereds",
  "Ordern är redo att levereras",
  "Ordern är på väg",
];

const getStatusIcon = (status) => {
  switch (status) {
    case "Beställningen är bekräftad":
      return <CheckCircle />;
    case "Ordern förbereds":
      return <Store />;
    case "Ordern är redo att levereras":
      return <LocalShipping />;
    case "Ordern är på väg":
      return <DirectionsCar />;
    default:
      return null;
  }
};*/
// Axios client setup
const axiosClient = axios.create({
  baseURL: getApiBaseUrl(),
  headers: {
    "Content-Type": "application/json",
  },
});

const tableStyle = {
  cell: { padding: 0, margin: 0 },
  articleNameCell: { padding: "0 15px 0 15px ", margin: 0 },
  articleAmountCell: { padding: "0 15px 0 0", margin: 0 },
  articleUnitsCell: { padding: "0 15px 0 0", margin: 0 },
  row: { padding: 0, margin: 0 },
  body: { padding: 0, margin: 0 },
  head: { padding: 0, margin: 0 },
  table: { padding: 0, margin: 0 },
};
const DeliveryStatusPage = () => {
  const { token } = useParams();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const isAuthenticated = useMemo(
    () => sessionStorage.getItem("tid") !== null,
    []
  );

  // Move getData into useCallback to memoize it
  const getData = useCallback(() => {
    axiosClient
      .post("/deliverystatus/getdeliverystatus", { token })
      .then((response) => {
        setData(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  }, [token]); // Include token in dependencies

  // Now include getData in useEffect dependencies
  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <Grid
      container
      sx={{
        margin: "0 auto",
        alignItems: "center",
        alignContent: "center",
        display: "flex",
        flexWrap: "wrap",
        flexDirection: "column",
      }}
    >
      <Grid item xs={12} sx={{ padding: "2rem" }}>
        <h2>Leveransstatus</h2>
      </Grid>
      {loading ? (
        <BettinaLoader loadingText={"Laddar order..."} />
      ) : (
        <Card
          sx={{
            boxShadow: 1,
            padding: { xs: 0, md: "2rem" },
            backgroundColor: data.picked ? "rgb(192, 216, 193)" : "white",
          }}
        >
          <Grid container spacing={2} sx={{ padding: "1rem" }}>
            <Grid item xs={6} sx={{ padding: "1rem" }}>
              <Typography variant="caption">Ordernummer</Typography>
              <Typography variant="body1"> {data.orderId}</Typography>
            </Grid>
            <Grid item xs={6} sx={{ padding: "1rem" }}>
              <Typography variant="caption">Leveransdatum</Typography>
              <Typography variant="body1">
                {getDateDescriptionAndTime(new Date(data.deliveryDate))}
              </Typography>
            </Grid>
            <Grid item xs={12} sx={{ padding: "1rem" }}>
              {data.comment || data.freightComment}
            </Grid>
          </Grid>
          <Divider />
          <Table sx={tableStyle.table}>
            <TableHead sx={tableStyle.head}>
              <TableRow sx={tableStyle.row}>
                <TableCell sx={tableStyle.articleNameCell}>
                  <Typography variant="body1">Artikel</Typography>
                </TableCell>
                <TableCell sx={tableStyle.articleAmountCell}>
                  <Typography variant="body1">Beställt</Typography>
                </TableCell>
                <TableCell sx={tableStyle.articleUnitsCell}>
                  <Typography variant="body1">Plockat</Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody sx={tableStyle.body}>
              {data?.articles?.length > 0 &&
                data.articles.map((a, index) => (
                  <React.Fragment>
                    <TableRow
                      key={index + "1"}
                      sx={{
                        backgroundColor: getArticleRowBackground(a),
                        ...tableStyle.row,
                        [`& .${tableCellClasses.root}`]: {
                          borderBottom: "none",
                        },
                      }}
                    >
                      <TableCell sx={tableStyle.articleNameCell}>
                        <Typography variant="body1" sx={{ fontSize: "1.1rem" }}>
                          {a.fullArticleName || a.articleName}
                        </Typography>
                      </TableCell>
                      <TableCell sx={tableStyle.articleAmountCell}>
                        <Typography variant="body1" sx={{ fontSize: "1.1rem" }}>
                          {a.articleAmount}
                        </Typography>
                      </TableCell>
                      <TableCell sx={tableStyle.articleAmountCell}>
                        <Typography variant="body1" sx={{ fontSize: "1.1rem" }}>
                          {a.pickedItemQuantityBoxes}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow
                      key={index + "2"}
                      sx={{
                        ...tableStyle.row,
                        backgroundColor: getArticleRowBackground(a),
                        borderBottom:
                          index !== data.articles?.length - 1 &&
                          "1px solid gray",
                      }}
                    >
                      <TableCell sx={tableStyle.articleNameCell}></TableCell>
                      <TableCell sx={tableStyle.articleAmountCell}>
                        {a.isWeightArticle && a.articleUnits > 0
                          ? a.articleUnits + a.unit
                          : ""}
                      </TableCell>
                      <TableCell sx={tableStyle.articleAmountCell}>
                        {a.isWeightArticle && a.pickedItemQuantityUnits > 0
                          ? a.pickedItemQuantityUnits + a.unit
                          : ""}
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                ))}
            </TableBody>
          </Table>
        </Card>
      )}
      <Box sx={{ textAlign: "center", padding: "1rem" }}>
        <Typography variant="caption">
          Senast uppdaterad {getLocalDateTime(data.dateEdited, "dateandtime")}
        </Typography>
      </Box>
      {isAuthenticated && (
        <Box sx={{ textAlign: "center", padding: "3rem 1rem" }}>
          <Button
            onClick={() => window.open("/mypurchaseorders")}
            startIcon={<ShoppingCart />}
            sx={{ textTransform: "none" }}
            color="primary"
            variant="contained"
          >
            Visa alla mina beställningar
          </Button>
        </Box>
      )}
    </Grid>
  );
};
export default DeliveryStatusPage;
